<template>
	<div class="trial">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试驾详情"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		:right-text="iscomeend?'结束试驾':''"
		@click-right="handFinish"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		<!-- 头部信息 -->
		<div class="head" v-if="info.condition > 2">
			<img src="@/assets/trial/icon_error.png">
			本次试乘试驾未完成
		</div>
		
		<!-- 客户信息 -->
		<div style="height: 5px;"></div>
		<div class="customer" :class="{'active':info.if_cruise == 1}">
			<div class="customer__title">
				客户信息
			</div>
			<div v-if="info.condition != 1" class="customer__sn">
				{{ info.number }}
			</div>
			<div class="customer__row">
				<div class="customer__row__title">{{info.name}}</div>
				<img style="margin-left: 10px;" v-if="info.gender == 1" src="@/assets/trial/icon_man.png">
				<img style="margin-left: 10px;" v-if="info.gender == 0" src="@/assets/trial/icon_woman.png">
			</div>
			<div class="customer__row">
				<div class="customer__row__title">联系方式：</div>
				<div class="customer__row__con">{{info.mobile}}</div>
			</div>
			<div class="customer__row">
				<div class="customer__row__title">试驾车型：</div>
				<div class="customer__row__con">{{info.model_name}} {{info.collocation_name}}</div>
			</div>
			<div class="customer__car" v-if="info.license_name">
				车牌：{{info.license_name}}
			</div>
			<div v-if="info.condition != 1" class="customer__row">
				<div class="customer__row__title">试驾时间：</div>
				<div class="customer__row__con">{{info.scsjTime?.slice(0,info.scsjTime?.indexOf(' '))}}</div>
			</div>
			<div v-if="info.condition != 1" class="customer__row">
				<div class="customer__row__title" style="opacity:0;">试驾时间：</div>
				<div class="customer__row__con">{{info.scsjTime?.slice(info.scsjTime?.indexOf(' '))}}<span v-if="info.condition>2" style="margin-left: 5px;">(未完成)</span></div>
			</div>
			<!-- <div class="condition">{{ info.condition == 2?'已完成':'未完成' }}</div> -->
			<div class="customer__agreement">
				<div class="customer__agreement__title">试乘试驾协议</div>
				<div class="customer__agreement__btn" @click="handAgreement">{{info.condition == 1 ? '立即签署' : '查看'}}</div>
			</div>
			<div class="customer__btn" v-if="info.condition == 1" @click="handEdit">
				编辑
			</div>
			<div class="customer__kdlk" v-if="info.condition != 1">
				<img src="@/assets/trial/icon_kdlk.png">
			</div>
		</div>
		
		<!-- 邀请函 -->
		<div class="inviter" v-if="info.scsj_invitation">
			<div class="inviter__title">
				邀请函
			</div>
			<div class="inviter__main" @click="gopath">
				<div class="inviter__base">
					<div class="inviter__customer">
						<span>{{info.scsj_invitation.customer_name}}</span>
						<span>{{info.scsj_invitation.customer_mobile}}</span>
					</div>
					<div class="inviter__car">
						凯迪拉克 {{info.scsj_invitation.model_name}}
					</div>
					<div class="inviter__time">
						{{info.scsj_invitation.appointment_time}}
					</div>
				</div>
				<div class="inviter__btn">
					<img src="@/assets/trial/icon_right.png">
				</div>
			</div>
		</div>
		
		<!-- 用户信息 -->
		<div class="user">
			<div class="user__title">
				产品专家
			</div>
			<div class="user__list">
				<div class="user__list__item" v-for="item,key in info.user" :key="item.id">
					<div class="usered" v-if="key == 0 || info.invite_status == 2">
						<van-image class="img_avatar" round :src="item.avatar" fit="cover"/>
						<div class="usered__name">{{item.username}}</div>
					</div>
					<div class="usering" v-if="key > 0 && info.invite_status != 2">
						<van-image class="img_avatar" round :src="item.avatar" fit="cover"/>
						<div class="usering__name">{{item.username}}</div>
						<div class="usering__btn">
							待确认
						</div>
					</div>
				</div>
				<div class="user__list__btn" v-if="info.user.length < 2 && info.condition == 1" @click="handInviter">
					+邀请协助
				</div>
			</div>
		</div>
		
		<!-- 试驾流程 -->
		<div class="process">
			<div class="process__title">
				试驾流程
			</div>
			<div class="process__main">
				<div class="process__left">
					<div class="process__status">
						<img src="@/assets/trial/icon_step_checked.png">
					</div>
					<div :class="['process__line', (info.condition != 1 || info.scsj_route_id) ? 'process__line--active' : '']"></div>
					<div class="process__status">
						<img v-if="info.condition == 1 && !info.scsj_route_id" src="@/assets/trial/icon_step.png">
						<img v-if="info.condition != 1 || info.scsj_route_id" src="@/assets/trial/icon_step_checked.png">
					</div>
					<div :class="['process__line', info.condition != 1 ? 'process__line--active' : '']"></div>
					<div class="process__status">
						<img v-if="info.condition == 1" src="@/assets/trial/icon_step.png">
						<img v-if="info.condition != 1" src="@/assets/trial/icon_step_checked.png">
					</div>
				</div>
				<div class="process__right">
					<div class="process__step" v-for="item,key in info.processList" :key="item.id">
						<div class="process__step__con">
							<div class="process__step__con__title">
								{{item.name}}
							</div>
							<div class="process__step__con__init" v-if="item.status == 0">{{key == 0 || key == 1 ? '待确认' : '待反馈'}}</div>
							<div class="process__step__con__init" v-if="item.status == 1">{{key == 0 || key == 1 ? '已确认' : '已反馈'}}</div>
						</div>
						<div @click="handRoute" v-if="key == 0 && item.if_start == 1" class="process__step__btn1">查看</div>
						<div @click="handRoute" v-if="key == 0 && item.if_start == 0" class="process__step__btn2">立刻创建</div>
						<div v-if="key == 0 && item.if_start == 3" class="process__step__btn3">未设置</div>
						<div v-if="key == 0 && item.if_start == 2" class="process__step__btn3">暂不可用</div>
						
						<div @click="handSee" v-if="key == 1 && item.if_start == 1" class="process__step__btn1">查看</div>
						<div @click="handSee" v-if="key == 1 && item.if_start == 0" class="process__step__btn2">立即开始</div>
						<div v-if="key == 1 && item.if_start == 3" class="process__step__btn3">未设置</div>
						<div v-if="key == 1 && item.if_start == 2" class="process__step__btn3">暂不可用</div>
						
						<div @click="handReport" v-if="key == 2 && item.if_start == 1" class="process__step__btn1">查看</div>
						<div @click="handReportEdit" v-if="key == 2 && item.if_start == 0" class="process__step__btn2">立即填写</div>
						<div @click="handReportEdit" v-if="key == 2 && item.if_start == 4" class="process__step__btn2">编辑</div>
						<div v-if="key == 2 && item.if_start == 3" class="process__step__btn3">未设置</div>
						<div v-if="key == 2 && item.if_start == 2" class="process__step__btn3">暂不可用</div>
					</div>
				</div>
			</div>
		</div>


		<van-dialog v-model="show" @confirm="handFinishReq" title="完成体验" show-cancel-button>
			<div class="user__inviter">
				确定要结束本次试乘试驾吗？
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { getDateTime6,getDateTime3 } from '@/utils/utils.js'
	
export default {
	data() {
		return {
			info: {
				number: '',
				processList: [],
				user: [],
				
			},
			iscomeend:false,
			show:false,
		};
	},
	mounted() {
		if (this.$route.query?.number) this.info.number = this.$route.query.number
		this.getTrialInfo()
		this.getTrialProcess()
	},
	methods: {
		async gopath(){
			this.$router.push({
				path: `/trial-invitation?id=${this.info.scsj_invitation.id}`
			})
		},
		async getScsjMine(e) {
			const {data: result} = await this.$http.post('/api/scsj/user_scsj_status_1_1', {})
			if (result.code == 1) {
				console.log(result,result)
				if(result.data.res != null){
						if(this.info.number == result.data.res.number){
							this.iscomeend =true
						}
				}
			}
		},
		// 结束试驾
		handFinish(e) {
			this.show = true
		},
		async handFinishReq(e) {
			const {data: result} = await this.$http.post('/api/scsj/finish_scsj_1_1', {
				number: this.info.number,
				condition: 3
			})
			if (result.code == 1) {
				this.getTrialInfo()
				this.getTrialProcess()
				return this.$toast.success(result.msg)
			} else {
				return this.$toast.fail(result.msg)
			}
		},

		// 编辑
		handEdit(e) {
			this.$router.push({
				path: `/trial-edit?number=${this.info.number}`
			})
		},
		
		// 邀请协助
		handInviter(e) {
			this.$router.push({
				path: `/trial-inviter?number=${this.info.number}`
			})
		},
		
		// 查看试驾报告
		handReport(){
			this.$router.push({
				path: this.info.if_cruise == 1 ?
					`/trial-reportinfo-super?number=${this.info.number}` : 
					`/trial-report?number=${this.info.number}`
			})
		},
		
		// 创建试驾报告
		handReportEdit(e) {
			this.$router.push({
				path: this.info.if_cruise == 1 ?
					`/trial-data-super?number=${this.info.number}` : 
					`/trial-data?number=${this.info.number}`
			})
		},
		
		// 预览路线
		handRoute(e) {
			this.$router.push({
				path: this.info.if_cruise == 1 ?
					`/trial-route-super?number=${this.info.number}` : 
					`/trial-route?number=${this.info.number}`
			})
		},
		
		// 体验
		handSee(e) {
			this.$router.push({
				path: this.info.if_cruise == 1 ?
					`/trial-see-super?number=${this.info.number}` : 
					`/trial-see?number=${this.info.number}`
			})
		},
		
		// 签署协议
		async handAgreement(e) {
			if (this.info.condition != 1) {
				return this.$router.push({
					path: `/trial-agreement?number=${this.info.number}`
				})
			}
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_treaty_info', {
				number: this.info.number
			})
			if (result.code == 1 && result.data.res.driving_license_status == 1) {
				this.$router.push({
					path: `/trial-agreement?number=${this.info.number}`
				})
			} else {
				this.$router.push({
					path: `/trial-drive?number=${this.info.number}`
				})
			}
		},
		
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.getScsjMine()
				this.info.scsjTime = getDateTime6(this.info.starttime, this.info.finishtime)
				this.info.user.forEach(v => {
					v.avatar = this.serverUrl(v.avatar)
				})
				if(this.info.scsj_invitation){
					this.info.scsj_invitation.appointment_time = getDateTime3(this.info.scsj_invitation.appointment_time)
				}
				
			}
		},
		
		// 试驾步骤
		async getTrialProcess(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_process_node_1_1', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info.processList.length = 0
				result.data.res.forEach(v => {
					this.info.processList.push(v)
				})
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.condition{

}
.head{
	width: 100%;
	box-sizing: border-box;
	height: 92px;
	background: #F5D98F;
	display: flex;
	align-items: center;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #FA0037;
	padding: 0 40px;
	img{
		width: 32px;
		height: 32px;
		margin-right: 20px;
	}
}
.customer{
	margin: 0 auto;
	width: 686px;
	padding: 32px;
	box-sizing: border-box;
	background: #222222;
	border-radius: 24px;
	position: relative;
	.customer__sn{
		margin-bottom: 20px;
		font-size: 30px;
		font-family: HYQiHeiY3-45;
		color: #ECECEC;
		opacity: 0.5;
		line-height: 40px;
	}
	&__title{
		margin-bottom: 30px;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #ECECEC;
		line-height: 40px;
	}
	&__row{
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		height: 40px;
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
		}
		&__con{
			font-size: 28px;
			font-family: Regular;
			color: #FFFFFF;
		}
		img{
			margin-right: 20px;
			width: 32px;
			height: 32px;
		}
	}
	&__car{
		display: inline-block;
		padding: 0 10px;
		margin-bottom: 20px;
		line-height: 48px;
		background: #F3C846;
		border-radius: 8px;
		font-size: 28px;
		font-family: HYQiHeiY3-55;
		color: #333333;
	}
	&__agreement{
		border-top: 1px solid #333;
		padding-top: 30px;
		display: flex;
		justify-content: space-between;
		&__title{
			font-size: 32px;
			font-family: HYQiHeiY3-55;
			color: #FFFFFF;
			line-height: 58px;
		}
		&__btn{
			width: 184px;
			font-size: 30px;
			font-family: HYQiHeiY3-55;
			border: 1px solid rgba(255, 255, 255, 0.3);
			border-radius: 8px;
			color: #FFFFFF;
			text-align: center;
			line-height: 58px;
		}
	}
	&__btn{
		position: absolute;
		top: 22px;
		right: 32px;
		width: 130px;
		border-radius: 8px;
		border: 1px solid rgba(255, 255, 255, 0.3);
		font-size: 30px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
		text-align: center;
		line-height: 58px;
	}
	&__kdlk{
		position: absolute;
		top: 36px;
		right: 32px;
		width: 96px;
		height: 36px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}
.inviter{
	
	margin-top: 60px;
	padding: 0 32px;
	&__title{
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #ECECEC;
		line-height: 40px;
		margin-bottom: 32px;
	}
	&__main{
		width: 100%;
		padding: 32px 22px 32px 32px;
		box-sizing: border-box;
		background: #373C46;
		border-radius: 12px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.inviter__base{
			.inviter__customer{
				font-size: 36px;
				font-family: HYQiHeiY3-55;
				color: #FFFFFF;
				line-height: 36px;
				span:last-child{
					font-size: 30px;
					margin-left: 10px;
					font-family: Regular;
					color: #9B9B9B;
				}
			}
			.inviter__car{
				margin-top: 24px;
				font-size: 36px;
				font-family: HYQiHeiY3-55;
				color: #9B9B9B;
				line-height: 36px;
			}
			.inviter__time{
				margin-top: 24px;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				color: #9B9B9B;
				line-height: 28px;
			}
		}
		.inviter__btn{
			img{
				width: 40px;
				height: 32px;
			}
		}
	}
	
}
.user{
	box-sizing: border-box;
	margin: 32px auto 0;
	width: 686px;
	padding: 28px 32px 44px;
	background: #373C46;
	border-radius: 24px;
	&__title{
		margin-bottom: 56px;
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #FFFFFF;
		line-height: 40px;
	}
	&__list{
		display: flex;
		&__item{
			width: 50%;
			.usered{
				width: 100%;
				height: 72px;
				display: flex;
				align-items: center;
				font-size: 36px;
				font-family: HYQiHeiY3-45;
				color: #FFFFFF;
				&__name{
					overflow: hidden;
					white-space: nowrap;
					// text-overflow: ellipsis;
				}
				.img_avatar{
					flex-shrink: 0;
					width: 72px;
					height: 72px;
					margin-right: 20px;
					border-radius: 50%;
				}
			}
			.usering{
				padding: 0 16px;
				height: 72px;
				border: 1px solid rgba(255, 255, 255, 0.2);
				display: flex;
				align-items: center;
				font-size: 32px;
				font-family: HYQiHeiY3-45;
				color: #B0B0B0;
				&__name{
					overflow: hidden;
					white-space: nowrap;
					// text-overflow: ellipsis;
				}
				&__btn{
					flex-shrink: 0;
					margin-left: 14px;
					width: 104px;
					height: 48px;
					background: #C8002B;
					border-radius: 8px;
					font-size: 26px;
					font-family: HYQiHeiY3-55;
					color: #ECECEC;
					text-align: center;
					line-height: 48px;
				}
				.img_avatar{
					flex-shrink: 0;
					width: 48px;
					height: 48px;
					margin-right: 14px;
					border-radius: 50%;
				}
			}
		}
		&__btn{
			width: 204px;
			height: 72px;
			border: 1px solid rgba(255, 255, 255, 0.2);
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #FFFFFF;
			text-align: center;
			line-height: 72px;
		}
	}
}
.process{
	margin-top: 60px;
	padding-left: 40px;
	padding-bottom: 60px;
	&__title{
		font-size: 40px;
		font-family: HYQiHeiY3-65;
		color: #ECECEC;
		line-height: 40px;
	}
	&__main{
		margin-top: 52px;
		display: flex;
	}
	.process__left{
		margin-right: 36px;
		margin-top: 40px;
		.process__status{
			width: 44px;
			height: 44px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.process__line{
			margin-left: 20px;
			width: 4px;
			height: 140px;
			background: #282828;
		}
		.process__line--active{
			background: #E7B000;
		}
	}
	&__right{
		.process__step{
			width: 620px;
			box-sizing: border-box;
			height: 120px;
			background: #222222;
			border-radius: 16px 0 0 16px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 40px;
			margin-bottom: 60px;
			&__con{
				&__title{
					font-size: 36px;
					font-family: HYQiHeiY3-55;
					color: #C4C4C4;
					line-height: 40px;
				}
				&__init{
					font-size: 24px;
					font-family: HYQiHeiY3-45;
					opacity: 0.5;
					color: #C4C4C4;
					line-height: 26px;
				}
			}
			&__btn1{
				width: 172px;
				border-radius: 8px;
				border: 1px solid #373737;
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				color: #ECECEC;
				text-align: center;
				line-height: 64px;
			}
			&__btn2{
				width: 172px;
				border-radius: 8px;
				border: 1px solid #FA0037;
				background: #FA0037;
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				color: #ECECEC;
				text-align: center;
				line-height: 64px;
			}
			&__btn3{
				width: 172px;
				border-radius: 8px;
				border: 1px solid #373737;
				font-size: 32px;
				font-family: HYQiHeiY3-55;
				color: #373737;
				text-align: center;
				line-height: 64px;
			}
		}
		.process__step:last-child{
			margin-bottom: 0;
		}
	}
}

.trial__close__box{
	padding: 20px 32px;
}
.trial__close__btn{
	width: 100%;
	line-height: 88px;
	background: #fa0037;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #F4F4F4;
	text-align: center;
}

.user__inviter{
	font-family: HYQiHeiY3-45;
	text-align: center;
	padding: 20px 0;
}

.active{
	background: url('http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_super.png');
	background-repeat: 100% 100%;
	
}
</style>
